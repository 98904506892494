/* Main chat messages container styles */
.chat-messages {
  width: 100%;  /* Makes the chat container take up full width of its parent */
  overflow-y: auto; /* Enables vertical scrolling when content overflows */
  scrollbar-width: thin; /* Makes scrollbar thinner (Firefox) */
  scrollbar-color: #c65e41 #F5F4EF; /* Sets scrollbar colors (Firefox) - thumb color #c65e41, track color #F5F4EF */
  padding-top: 60px; /* Push messages down below buttons */
  clip-path: inset(50px 0px 0px 0px); /* Clip messages when they scroll up */


}

/* Webkit (Chrome/Safari) scrollbar styles */
.chat-messages::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.chat-messages::-webkit-scrollbar-track {
  background: #F5F4EF; /* Color of the scrollbar track/background */
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #c65e41; /* Color of the scrollbar thumb/handle */
  border-radius: 4px; /* Rounded corners on the scrollbar thumb */
}

/* Typing indicator container styles */
.typing-indicator {
  display: flex; /* Uses flexbox for layout */
  justify-content: flex-start; /* Aligns dots to the start of container */
  align-items: center; /* Centers dots vertically */
  padding: 10px 15px; /* Inner spacing: 10px top/bottom, 15px left/right */
  max-width: 60%; /* Limits width of typing indicator */
}

/* Individual dot styles for typing animation */
.dot {
  width: 8px; /* Width of each dot */
  height: 8px; /* Height of each dot */
  background-color: #bbb; /* Color of dots */
  border-radius: 50%; /* Makes dots circular */
  margin-right: 5px; /* Space between dots */
  animation: bounce 1.5s infinite; /* Applies bounce animation, repeats infinitely */
}

/* Delay for second dot animation */
.dot:nth-child(2) {
  animation-delay: 0.2s; /* Starts animation 0.2s after first dot */
}

/* Delay for third dot animation */
.dot:nth-child(3) {
  animation-delay: 0.4s; /* Starts animation 0.4s after first dot */
}

/* Bounce animation keyframes */
@keyframes bounce {
  0%, 60%, 100% {
    transform: translateY(0); /* Dot position at start, 60%, and end */
  }
  30% {
    transform: translateY(-5px); /* Dot jumps up 5px at 30% of animation */
  }
}

/* Blinking cursor animation keyframes */
@keyframes blink {
  from, to {
    border-color: transparent; /* Cursor invisible at start and end */
  }
  50% {
    border-color: #3C392B; /* Cursor visible at middle of animation */
  }
}
